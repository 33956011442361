@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Work+Sans&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  /*outline: 1px solid pink;*/
  padding: 0;
}

body {
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Comfortaa', sans-serif;
}

nav {
  align-items: flex-end;
  background-color: #ebebeb;
  color: #000814;
  display: flex;
  gap: 2rem;
  min-height: 10rem;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2rem 5rem;
}

.site-title,
.site-title * {
  width: 100%;
  max-width: 526px;
}

.nav-list {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  list-style: none;
}

.nav-list a {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 1.5rem;
  height: 100%;
  padding: .25rem;
  text-decoration: none;
}

.nav-list a:hover {
  color: #003566;
}

.nl-mini a {
  font-size: 1.2rem;
}

footer {
  background-color: #000814;
  color: #ebebeb;
  height: 12rem;
  padding: 0 5rem;
}

footer a {
  color: inherit;
  text-decoration: none;
}

.site-footer {
  border-bottom: solid .1rem #c0c0c0;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
}

.footer-contact-list {
  display: flex;
  gap: 1rem;
  justify-content: center;
  list-style-type: none;
}

.img-banner {
  align-items: center;
  color: #ebebeb;
  display: flex;
  justify-content: center;
  min-height: 35rem;
}

.img-banner>img {
  height: 35rem;
  object-fit: cover;
  width: 100%;
}

.default-section {
  display: flex;
  flex-flow: row wrap;
  gap: 5rem;
  justify-content: center;
  padding: 5rem;
}

.default-section>* {
  flex-basis: 50rem;
}

.section-entry>p {
  line-height: 2rem;
}

.section-entry {
  flex-basis: 50rem;
}

.latest-project-img {
  width: 100%;
}

.img-banner-alt {
  align-items: center;
  color: #ebebeb;
  display: flex;
  min-height: 35rem;
  padding: 2rem 5rem;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.img-banner-alt>aside {
  display: flex;
  flex-basis: 35rem;
  flex-flow: column wrap;
}

.about-page-banner {
  background: #c0c0c0 url("./assets/IMG_1403.jpg") no-repeat 50% 75%;
  background-size: cover;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.55);
}

.services-page-banner {
  background: #c0c0c0 url("./assets/pexels-john-guccione-wwwadvergroupcom-4134179.jpg") no-repeat 50% 75%;
  background-size: cover;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.5);
}

.services-page-contact-banner {
  background: #c0c0c0 url("./assets/thisisengineering-raeng-xYCBw1uIP_M-unsplash.jpg") no-repeat 50% 75%;
  background-size: cover;
  background-position: 40% 50%;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.5);

}

.projects-page-banner {
  background: #c0c0c0 url("./assets/sergey-zolkin-m9qMoh-scfE-unsplash.jpg") no-repeat 50% 75%;
  background-size: cover;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.25);
}

.contact-page-banner {
  background: #c0c0c0 url("./assets/petr-machacek-BeVGrXEktIk-unsplash.jpg") no-repeat;
  background-size: cover;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.45);
}

.projects-banner {
  background: #c0c0c0 url("./assets/THSR_700T_TR17_20130907.jpg") no-repeat 50% 75%;
  background-size: cover;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.25);
}

.service-img {
  background-color: #003566;
  display: block;
  height: 15rem;
  object-fit: cover;
  object-position: center bottom;
  width: 100%;
  border-radius: .5rem .5rem 0 0;
}

.pos-center-top {
  object-position: center top;
}

.pos-center {
  object-position: left top !important;
}

.service-sub-section h3 {
  padding-top: 0.5rem;
}

.nav-btn {
  font-size: 20px;
  padding: 0.5em 2em;
  border: transparent;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  background: dodgerblue;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  margin-top: .5rem;
}

.nav-btn:hover {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(30, 144, 255, 1) 0%, rgba(0, 212, 255, 1) 100%);
}

.nav-btn:active {
  transform: translate(0em, 0.1em);
}

.center-heading {
  align-self: center;
  text-align: center;
}

.card-list {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  list-style: none;
  gap: 2.5rem;
  padding: 2rem 10rem;
}

.card-list>li {
  flex-basis: 25rem;
}

.center-section {
  padding: 5rem;
}

.img-round_corners {
  border-radius: .5rem;
}

.card-list>li>h3 {
  margin-top: .5rem;
}

.section-card {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact-card {
  padding: 2rem;
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.5);
  border-radius: .5rem;
  list-style: none;

  justify-content: center;
  align-items: center;
}

.contact-card a {
  color: inherit;
}

.section-entry>a {
  display: flex;
  margin-bottom: 1rem;
}

.section-img {
  width: 100%;
  max-width: 800px;
}

.form-flex {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;

  position: relative;
}

.form-flex>.tab-content {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: stretch;

  position: relative;
}

.form-flex-alt-spaceBet {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  position: relative;
}

.delBtn {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}

@media screen and (max-width: 500px) {
  .default-section {
    padding: 0;
  }

  
  .reportForm input,
  .reportForm textarea,
  .reportForm select,
  .reportForm button {
    font-size: 1rem;
  }
  

  .reportForm input[type=checkbox] {
    height: 1.5rem;
    width: 1.5rem;
  }


}

.tab-checkbox {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tabs {
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: .5em;
  background: #2c3e50;
  font-weight: bold;
  cursor: pointer;
}

.tab-label:hover {
  background: #1a252f;
}

.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.15s;
}

.tab-content {
  max-height: 0;
  padding: 0 0em;
  color: #2c3e50;
  background: white;
  transition: all 0.15s;
}

.tab-content>*, .tab-content>fieldset>*, .tab-content>div>* {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.tab-checkbox:checked + .tab-label {
  background: #1a252f;
}

.tab-checkbox:checked + .tab-label::after {
  transform: rotate(90deg);
}

.tab-checkbox:checked ~ .tab-content {
  max-height: 100%;
  padding: .5em;
}